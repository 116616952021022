import {Map as ImmutableMap} from "immutable";
import {getConfig} from "./envUtil";

export const handleEnter = (cb: () => any) => (event: any) => {
    if (event.key === "Enter") {
        cb();
    }
};

export function mapToArrayWithRoundNumbers<T>(map: ImmutableMap<T, number>, digits: number)
    : { value: string; key: T }[] {

    return map.map((numValue, key) => {
        return {key, value: numValue.toFixed(digits)};
    }).valueSeq().toArray();
}

export function groupBy(objectsArray: any[], groupByProperty: string, valueProperty: string)
    : ImmutableMap<any, number> {
    return objectsArray.reduce((acc, holding) => {
        const value = isNaN(holding[valueProperty])
            ? 0
            : holding[valueProperty];
        const key = holding[groupByProperty];
        if (acc.has(key)) {
            return acc.set(key, (acc.get(key) + value));
        } else {
            return acc.set(key, value);
        }
    }, ImmutableMap<any, number>());
}

export const isNullOrUndefined = (value: any): value is null | undefined => {
    return value === null || value === undefined;
};

export const capitalizeFirstLetter = (str: string) => {
    return str.toLowerCase().split(" ")
        .map((word: string) => word.substr(0, 1).toUpperCase() + word.substr(1))
        .join(" ");
};

export const getConfigPageSize = () => {
    const parsedSize = parseInt(getConfig().FRONTEND_CONFIG_PAGE_SIZE, 10);

    return isNaN(parsedSize) ? 20 : parsedSize;
};

export const trimToNearestWord = (subject: string, limit: number) => {
    if (subject.length <= limit) {
        return subject;
    }
    const temp = subject.substr(0, limit);

    return temp.lastIndexOf(" ") === -1
    ? temp
    : temp.substr(0, Math.min(temp.length, temp.lastIndexOf(" ")));
};


export const getNumber = (value: any): number => {
    return isNaN(value) ? 0.00 : +(Number(value).toFixed(2));
};
