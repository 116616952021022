import * as React from "react";
import {navigateTo} from "../../../navigateTo";
import {AdminLevelList, ConsultantLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {handleEnter} from "../../utils/commonUtil";
import {OktaLogOut} from "./OktaLogout";

export interface IMenuProps {
    username?: string;
    shown: boolean;
    closeMenuFn: () => void;
    userType: UserTypeEnum;
    setCorsErrorModalOpen: (is: boolean) => void;
}

export class Menu extends React.Component<IMenuProps, any> {
    private static truncateUsernameText(username: string): string {
        return username.substring(0, 25);
    }

    public constructor(props: any) {
        super(props);
    }

    public render() {
        if (!this.props.username) {
            return <div/>;
        }

        return (
            <div id="user-menu__container" data-testid="user-menu__container">
                {this.props.shown && <div className="menu__overlay" onClick={this.props.closeMenuFn}/>}
                <div id="menu" className={this.getMenuClass()} data-testid="menu">
                    <div className="title-bar">
                        <div className="close-icon fa-regular fa-x" onClick={() => this.props.closeMenuFn()}/>
                    </div>
                    <div className="username-card">
                        <div className="user-info" id="username" data-testid="username">
                            <div className="user-icon fa-light fa-user"/>
                            {this.displayUsername()}
                        </div>
                        {this.renderMyClientsPageLink()}
                        {this.renderAdminLinks()}
                        <OktaLogOut setCorsErrorModalOpen={this.props.setCorsErrorModalOpen}/>
                    </div>
                </div>
            </div>
        );
    }

    private getTabIndex() {
        return this.props.shown ? 0 : -1;
    }

    private renderAdminLinks() {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) { return null; }
        return<>
            <div className="clickable-menu-item">
                <a className="admin-page-link old-anchor"
                   tabIndex = {this.getTabIndex()}
                   onKeyUp={handleEnter(() => navigateTo("/admin"))}
                   onClick={() => navigateTo("/admin")}>User Administration</a>
            </div>
            <div className="clickable-menu-item">
                <a className="admin-client-plan-link old-anchor"
                   tabIndex={this.getTabIndex()}
                   onKeyUp={handleEnter(() => navigateTo("/admin-client-plan"))}
                   onClick={() => navigateTo("/admin-client-plan")}>
                    Client Plan Administration
                </a>
            </div>
            <div className="clickable-menu-item">
                <a className="admin-upload-page-link old-anchor"
                   tabIndex={this.getTabIndex()}
                   onKeyUp={handleEnter(() => navigateTo("/admin-upload"))}
                   onClick={() => navigateTo("/admin-upload")}>
                    Upload Files
                </a>
            </div>
            <div className="clickable-menu-item">
                <a className="admin-media-groups-page-link old-anchor"
                   tabIndex = {this.getTabIndex()}
                   onKeyUp={handleEnter(() => navigateTo("/admin-media-groups"))}
                   onClick={() => navigateTo("/admin-media-groups")}>
                    CPR Media Group Admin
                </a>
            </div>
            </>;
    }

    private renderMyClientsPageLink() {
        if (!doesUserHaveAccess(this.props.userType, [...ConsultantLevelList, UserTypeEnum.ATC_ADMIN])) { return null; }
        return <div className="clickable-menu-item">
            <a className="old-anchor" id="my-clients-link"
               tabIndex = {this.getTabIndex()}
               onKeyUp={handleEnter(() => navigateTo("/my-clients"))}
               onClick={() => navigateTo("/my-clients")}>My Clients</a>
        </div>;
    }

    private getMenuClass(): string {
        return "header__menu--" + ((this.props.shown) ? "shown" : "hidden");
    }

    private displayUsername() {
        return Menu.truncateUsernameText(this.props.username!);
    }
}
