import * as React from "react";
import {useState} from "react";
import OddIqNoBackstopPage from "./odd-page-no-backstop/OddIqNoBackstopPage";
import OddIqPage from "./odd-page/OddIqPage";
import {hasAonTrustCompany, hasOddPowerBiEnabled, hasProducts, oddIqEnabled} from "../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {ErrorComponent} from "../base/Error.component";
import {OddIqReport} from "./OddIqReport.component";
import {IApplicationRootState} from "../../../applicationState";

export interface IOddIqPageContainerProps {
    hasProducts: boolean;
    oddIqEnabled: boolean;
    oddPowerBiEnabled: boolean;
    aonTrustCompany: boolean;
}

export enum OddIqTabs {
    OPERATIONAL_RISK_PROFILE = "Operational Risk Profile",
    DATA_EXPLORER = "Data Explorer",
}

export const OddIqPageContainer: React.FunctionComponent<IOddIqPageContainerProps> = (props) => {
    const [currentTab, setCurrentTab] = useState(OddIqTabs.OPERATIONAL_RISK_PROFILE);

    const getSelected = (tab: OddIqTabs) => tab === currentTab ? "selected" : "";

    const renderTab = (tab: OddIqTabs) => {
        return <div className={`odd-page__tab clickable ${getSelected(tab)}`}
                    onClick={() => {
                        setCurrentTab(tab);
                    }
                    } data-testid="odd-page__tab">
            {tab}
        </div>;
    };

    const renderTabs = () => {
        return <div className="odd-page__tabs-container">
            {renderTab(OddIqTabs.OPERATIONAL_RISK_PROFILE)}
            {props.oddPowerBiEnabled ? renderTab(OddIqTabs.DATA_EXPLORER) : null}
        </div>;
    };

    const renderContent = () => {
        return currentTab === OddIqTabs.OPERATIONAL_RISK_PROFILE
        ? props.hasProducts ? <OddIqPage/> : <OddIqNoBackstopPage/>
            : <OddIqReport/>;
    };

    return props.oddIqEnabled && !props.aonTrustCompany
        ? <div className="odd-page__container main-content" data-testid="odd-iq-page-container">
            <div id="odd-page__portfolio-page">
                {renderTabs()}
                {renderContent()}
            </div>
        </div>
        : <ErrorComponent />;
};

export const mapStateToProps = (state: IApplicationRootState): IOddIqPageContainerProps => {
    return {
        hasProducts: hasProducts(state),
        oddIqEnabled: oddIqEnabled(state),
        oddPowerBiEnabled: hasOddPowerBiEnabled(state),
        aonTrustCompany: hasAonTrustCompany(state),
    };
};

const connected = connect<IOddIqPageContainerProps, {}>(mapStateToProps)(OddIqPageContainer);

export default connected;