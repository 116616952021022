import moment = require("moment");
import {getMonthDifference, parseShortDate} from "./dateUtil";
import {IPlanMarketValueLineChart} from "../components/portfolio/PlanMarketValueLineChart";

export const formatXAxisPeriodToMonth = (dateValue: string) => {
    const values = dateValue.split(" ");
    if (values.length < 3) {
        return "";
    }
    return values[1] + " " + values[2];
};

export const formatXAxisPeriodToYear = (value: string) => {
    if (value.split(" ").length < 3) {
        return "";
    }
    return value.split(" ")[2];
};

export const formatXAxisPeriodToYearForPlanMarketValue = (value: string) => {
    const endOfFirstQuarter = "31 Mar";
    if (value.split(" ").length < 3) {
        return "";
    }
    if(value.includes(endOfFirstQuarter)) {
        return value.split(" ")[2];
    }
    return "";
};

type TimePeriod = {
    name: string,
    value: number
};

const TIME_PERIODS: TimePeriod[] = [
    {name: "All", value: 0},
    {name: "10 Yrs", value: 120},
    {name: "5 Yrs", value: 60},
    {name: "3 Yrs", value: 36},
    {name: "1 Yr", value: 12},
    {name: "6 Mo", value: 6},
    {name: "3 Mo", value: 3}
];

export const getPlanMarketValueTimePeriods = (dates: string[]): string[] => {
    const timePeriod: string[] = [];
    if (dates.length > 0) {
        const latestDate = parseShortDate(dates[dates.length - 1]);
        TIME_PERIODS.forEach((value) => {
            const calculatedMoment = moment(latestDate).subtract(value.value, 'months');
            const daysInMonth = calculatedMoment.daysInMonth();
            const calculatedDate = calculatedMoment.format("DD MMM YYYY")
                .replace(latestDate.getDate().toString(), daysInMonth.toString());

            if (dates.includes(calculatedDate)) {
                timePeriod.push(value.name);
            }
        });
    }
    return Array.from(timePeriod).reverse();
};

export const filterMarketValuesByPeriod = (
    planMarketValues: IPlanMarketValueLineChart[],
    selectedTimePeriod: string) => {
    if (selectedTimePeriod === "All") {
        return planMarketValues;
    } else {
        const latestDate = parseShortDate(planMarketValues[planMarketValues.length - 1].asOfDate);
        const expectedGap = TIME_PERIODS.find(it => it.name === selectedTimePeriod)!.value;

        return planMarketValues.filter((value) => {
            const calculatedGap = getMonthDifference(parseShortDate(value.asOfDate), latestDate);
            return calculatedGap <= expectedGap;
        });
    }
};