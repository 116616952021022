import * as React from "react";
import {IInformPerformance} from "../../model/inform/InformPerformance";
import {getBarColor, getMinValue, getMonthlyExcessReturnChartData} from "./informUtil";
import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatXAxisPeriodToYear} from "../../utils/chartUtils";

export const PerformanceMonthlyExcessChart: React.FunctionComponent<IInformPerformance> = (props: IInformPerformance) => {
    const data = getMonthlyExcessReturnChartData(props.monthlyExcessReturnValues);
    const min = getMinValue(data);
    return <div className="inform-performance__monthly-excess-chart-container">
        <div className="inform-performance__monthly-returns">
            <div className="performance__positive-excess-returns">
                <div className="excess-returns__text">Positive Excess Returns Months</div>
                <div className="excess-returns__value">{props.positiveExcessReturnMonths}</div>
            </div>
            <div className="performance__negative-excess-returns">
                <div className="excess-returns__text">Negative Excess Returns Months</div>
                <div className="excess-returns__value">{props.negativeExcessReturnMonths}</div>
            </div>
        </div>
        <div className="inform-performance__monthly-excess-chart"
             data-testid="inform-performance__monthly-excess-chart">
            <div className="monthly-excess-chart__title" data-testid="monthly-excess-chart__title">
                <p>The strategy has outperformed its benchmark {props.percentageOfOutperformanceOverBenchmark} of
                    the time during the past {props.performanceAnalyticsPeriod}
                </p>
            </div>
            <div className="monthly-excess-chart__chart" data-testid="monthly-excess-chart__chart">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        height={400}
                        data={data}
                        margin={{top: 0, right: 0, left: 0, bottom: 0,}}
                    >
                        <CartesianGrid stroke="#ccc" vertical={false}/>
                        <XAxis axisLine={false} tickLine={false} dataKey="period"
                               interval={11}
                               tickFormatter={formatXAxisPeriodToYear}
                               className={"axis-label"}
                        />
                        <YAxis axisLine={false} tickLine={false} padding={{top: 20, bottom: 20}}
                               tickFormatter={value => `${value}%`}
                               domain={[min, "auto"]}
                               className={"axis-label"}
                        />
                        <Tooltip formatter={(value) => [`${value}%`, "Excess return"]}
                                 wrapperClassName={"recharts-default-tooltip"}
                        />
                        <ReferenceLine y={0} stroke="#000"/>
                        <Bar dataKey="value" maxBarSize={15}>
                            {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={getBarColor(entry.valueAsNumber)} cursor="pointer"/>
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>;
};