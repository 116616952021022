import * as React from "react";
import {CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatCurrency} from "../../utils/numberUtil";
import {
    filterMarketValuesByPeriod,
    formatXAxisPeriodToMonth,
    formatXAxisPeriodToYearForPlanMarketValue,
    getPlanMarketValueTimePeriods
} from "../../utils/chartUtils";

export interface IPlanMarketValueLineChart {
    asOfDate: string,
    value: number
}

export interface IPlanMarketValueLineChartProps {
    planMarketValues: IPlanMarketValueLineChart[]
}

export const PlanMarketValueLineChart: React.FunctionComponent<IPlanMarketValueLineChartProps> = (props) => {
    const [chartData, setChartData] = React.useState<IPlanMarketValueLineChart[]>(props.planMarketValues);
    const [selectedPeriod, setSelectedPeriod] = React.useState<string>("All");
    const maxValue = Math.max(...chartData.map(it => it.value));

    const hasLessThanOrEqualToEightRecords = chartData.length <= 8;

    const getClassNames = (period: string) => {
        return `plan-market-value__chart-time-period-item ${selectedPeriod === period ? "underline" : ""}`;
    };

    const renderTimePeriod = () => {
        const timePeriods = getPlanMarketValueTimePeriods(props.planMarketValues.map(it => it.asOfDate));
        return timePeriods.length > 0 && <div className={"plan-market-value__chart-time-period-container"}
                    data-testid={"plan-market-value__chart-time-period-container"}>
            {timePeriods.map((period) => {
                return <a key={period} className={getClassNames(period)}
                          data-testid={"plan-market-value__chart-time-period-item"}
                          onClick={() => {
                              setSelectedPeriod(period);
                              setChartData(filterMarketValuesByPeriod(props.planMarketValues, period));
                          }}>
                    {period}
                </a>;
            })}
        </div>;
    };

    const renderClassForCurrencyDifference = (value: string) => {
        return value.trim().startsWith("-")
            ? "plan-market-value-chart-header__currency negative"
            : "plan-market-value-chart-header__currency positive";
    };

    const renderHeader = () => {
        const selectedPeriodCurrencyDifferenceValue = chartData[chartData.length -1].value - chartData[0].value;

        return <div className={"plan-market-value__chart-header-container"}
                    data-testid={"plan-market-value__chart-header-container"}>
            <div className={"plan-market-value-chart-header-total__container"}
                 data-testid={"plan-market-value-chart-header-total__container"}>
                <h3>Total Value</h3>
                <div className={"plan-market-value-chart-header__date"}>
                    as of {props.planMarketValues[props.planMarketValues.length -1].asOfDate}
                </div>
                <div className={"plan-market-value-chart-header__currency"}>
                    {formatCurrency(props.planMarketValues[props.planMarketValues.length -1].value, 0)}
                </div>
            </div>
            <div className={"plan-market-value-chart-header-selected-period__container"}
                 data-testid={"plan-market-value-chart-header-selected-period__container"}>
                <h3>{selectedPeriod === "All" ? "Change" : `${selectedPeriod} Change`}</h3>
                <div className={"plan-market-value-chart-header__date"}>
                    as of {chartData[0].asOfDate}
                </div>
                <div className={renderClassForCurrencyDifference(selectedPeriodCurrencyDifferenceValue.toString())}>
                    {selectedPeriodCurrencyDifferenceValue > 0
                        ? `+${formatCurrency(selectedPeriodCurrencyDifferenceValue, 0)}`
                        : formatCurrency(selectedPeriodCurrencyDifferenceValue, 0)
                    }
                </div>
            </div>
        </div>;
    };

    const renderChartContent = () => {
        return <div className={"plan-market-value__chart-container"} data-testid={"plan-market-value__chart-container"}>
            {renderTimePeriod()}
            <div className={"plan-market-value__chart-yaxis-name"}>Total value in Millions</div>
            <ResponsiveContainer width={"100%"} height={315}>
                <LineChart
                    height={315}
                    data={chartData}
                    margin={{top: 15, right: 0, left: 0, bottom: 0,}}
                    {...{overflow: "visible"}}
                >
                    <CartesianGrid stroke="#ccc" vertical={false}/>
                    <XAxis axisLine={false} tickLine={false} dataKey="asOfDate"
                           interval={0}
                           tickFormatter={hasLessThanOrEqualToEightRecords
                               ? formatXAxisPeriodToMonth
                               : formatXAxisPeriodToYearForPlanMarketValue}
                           className={"axis-label"}
                           dy={15}
                    />
                    <YAxis axisLine={false} tickLine={false}
                           domain={[
                               'auto',
                               maxValue
                           ]}
                           padding={{top: 0, bottom: 0}}
                           dataKey={"value"} type={"number"}
                           tickFormatter={value => formatCurrency(value/1000000,0)}
                           className={"axis-label"}
                           dx={-15}
                    />
                    <Tooltip formatter={(value: any) => formatCurrency(value, 0)}/>
                    <ReferenceLine y={0}/>
                    <Line dataKey="value" stroke="#0084BB" strokeWidth={2} dot={false}
                          name={`Value`}/>
                </LineChart>
            </ResponsiveContainer>
        </div>;
    };

    return <div className="plan-market-value-line-chart__container">
        {renderHeader()}
        {renderChartContent()}
    </div>;
};