import * as React from "react";
import {useState} from "react";
import {ErrorComponent} from "../base/Error.component";
import {hasConsultantLevelAccess, hasPortfolioPageAccess} from "../../utils/sessionUtil";
import {connect} from "react-redux";
import {
    hasClientPortfolioAccess,
    hasPortfolioAccess, portfolioProductSummariesSelector,
} from "../../../mainReducerMapSelectors";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";
import {IApplicationRootState} from "../../../applicationState";
import {getSuccessData} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {allProductsRequestPair} from "../common/RequesterPairs";
import {AssetAllocationTab} from "./AssetAllocationTab";
import {FinancialReconciliationTab} from "./FinancialReconciliationTab";

export interface IPortfolioPageProps {
    productSummaries: Map<number, ProductSummary>;
    portfolioAccess: boolean;
    clientPortfolioAccess: boolean;
}

export enum PortfolioTabs {
    ASSET_ALLOCATION = "Asset Allocation",
    FINANCIAL_RECONCILIATION = "Financial Reconciliation",
    PERFORMANCE = "Performance",
}

export const PortfolioPage: React.FunctionComponent<IPortfolioPageProps> = (props) => {
    const [currentTab, setCurrentTab] = useState(PortfolioTabs.ASSET_ALLOCATION);

    const renderClientAccessNotification = () => {
        return (hasConsultantLevelAccess() && !props.clientPortfolioAccess)
            ? <div className="portfolio-page__notification" data-testid={"portfolio-page__notification"}>
                <NoAccessNotification
                    message={"The Portfolio page is currently hidden from client users of this plan."}/>
            </div>
            : null;
    };

    const getSelected = (tab: PortfolioTabs) => tab === currentTab ? "selected" : "";

    const renderTabs = () => {
        return <div className="tabs__container">
            {renderTab(PortfolioTabs.ASSET_ALLOCATION)}
            {renderTab(PortfolioTabs.FINANCIAL_RECONCILIATION)}
            {renderTab(PortfolioTabs.PERFORMANCE)}
        </div>;
    };

    const renderTab = (tab: PortfolioTabs) => {
        return <div className={`tab clickable ${getSelected(tab)}`}
                    onClick={() => {
                        setCurrentTab(tab);
                    }}
                    data-testid="portfolio-page__tab">
            <h6>{tab}</h6>
        </div>;
    };

    const renderAssetAllocationTab = () => {
        return currentTab === PortfolioTabs.ASSET_ALLOCATION
            && <AssetAllocationTab productSummaries={props.productSummaries}/>;
    };
    const renderFinancialReconciliationTab = () => {
        return currentTab === PortfolioTabs.FINANCIAL_RECONCILIATION && <FinancialReconciliationTab/>;
    };

    const renderPerformanceTab = () => {
        return currentTab === PortfolioTabs.PERFORMANCE &&
            <div className="portfolio-page__performance-container" data-testid="portfolio-page__performance-container">
                <h2>Performance</h2>
            </div>;
    };

    const renderContent = () =>
        <div className="main-content new-common-styles portfolio__page-container"
             data-testid="portfolio__page-container">
            {renderClientAccessNotification()}
            <h1>Portfolio Overview</h1>
            {renderTabs()}
            {renderAssetAllocationTab()}
            {renderFinancialReconciliationTab()}
            {renderPerformanceTab()}
        </div>;

    return hasPortfolioPageAccess(props.portfolioAccess, props.clientPortfolioAccess)
        ? renderContent() : <ErrorComponent/>;
};

export const mapStateToProps = (state: IApplicationRootState): IPortfolioPageProps => {
    return {
        productSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        portfolioAccess: hasPortfolioAccess(state),
        clientPortfolioAccess: hasClientPortfolioAccess(state),
    };
};

const connectedPortfolioPageComponent = connect<IPortfolioPageProps>(mapStateToProps)(PortfolioPage);

export default multipleApiRequesterWrapper(connectedPortfolioPageComponent, [allProductsRequestPair()]);