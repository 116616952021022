import {ThemeProvider} from "@mui/material/styles";
import * as React from "react";
import {HTMLAttributes, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {navigateTo} from "../../../navigateTo";
import {getElementsByTagName} from "../../api/BrowserWrapper";
import {ResearchSearchItem} from "../../model/Search.model";
import {getElementById, getScrollOffset} from "../../utils/browserUtil";
import ProductActions, {default as productActions} from "../product/productActions";
import {getTranslation} from "../../utils/translationUtil";
import {createTheme} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {FilterOptionsState} from "@mui/base/useAutocomplete/useAutocomplete";
import {AonColors} from "../../../../css/Colors";

export const HEADER_HEIGHT = 64;
const NO_RESULTS_MESSAGE = "No strategy or manager results found.";

export interface ISubNavPropsFromActions {
    actions: {
        selectTicker: typeof ProductActions.selectTicker;
    };
}

export interface ISubNavMenuItem {
    id: string;
    name: string;
}

export interface ISubNavPropsFromParent {
    menuItems: ISubNavMenuItem[];
    searchEnabled: boolean;
    onItemSelected: (id: string) => void;
    selectedMenuItem: string;
    searchItems?: ResearchSearchItem[];
}

export type ISubNavProps =
    ISubNavPropsFromParent
    & ISubNavPropsFromActions;

export const SubNavComponent: React.FunctionComponent<ISubNavProps> = (props) => {
    const [searchVisible, setSearchVisible] = useState<boolean>(false);
    const [topOfSubNavY, setTopOfSubNavY] = useState<number>(HEADER_HEIGHT);
    const [searchItems, setSearchItems] = useState<ResearchSearchItem[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const NO_RESULTS_MESSAGE_TRANSLATED = getTranslation("subnav.search-no-results", NO_RESULTS_MESSAGE);

    function handleScroll() {
        setTopOfSubNavY(Math.max(0, HEADER_HEIGHT - getScrollOffset()));
    }

    function subNavScrolling() {
        return isScrolling() ? " sub-nav--scrolling" : "";
    }

    function renderSearch() {
        return searchVisible ? renderSearchBar() : renderSearchIcon();
    }

    function renderNavItems() {
        return props.menuItems
            .map((item) => {
                const selected = props.selectedMenuItem === item.id;
                return <a role="link" key={item.id}
                            id={`sub-nav__item-${item.id}`}
                            className={`sub-nav__item${selected ? " sub-nav__item--selected" : ""}`}
                            onClick={() => {
                                handleNavItemClick(item);
                            }}>
                    <FormattedMessage id={`subnav.${item.id}`}
                                      defaultMessage={item.name} />
                </a>;
            });
    }

    function handleNavItemClick(item: ISubNavMenuItem) {
        setSearchText("");
        setSearchVisible(false);
        props.onItemSelected(item.id);
    }

    function renderSearchBar() {
        return <div className="search__inner-container" data-testid="search__inner-container">
            <div id="menu-search-icon" className="search__icon far fa-search"/>
            {renderSearchNav()}
        </div>;
    }

    function renderSearchIcon() {
        return <div className="search__inner-container">
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <div className="search__clickable-container clickable" onClick={showSearch}
                 data-testid="search__clickable-container">
                <div id="menu-search-icon" className="search__icon far fa-search"/>
                <div className="search__header">
                    <FormattedMessage id="subnav.search" defaultMessage="Search"/>
                </div>
            </div>
        </div>;
    }

    function handleSearchItemSelected(item: ResearchSearchItem) {
        if (item.itemType !== "NOT_FOUND") {
            if (item.tickerId) {
                props.actions.selectTicker({tickerId: item.tickerId, productId: item.backstopId});
            }

            setSearchVisible(false);
            setSearchText("");

            navigateTo(item.pageUrl);
        } else {
            setSearchText("");
        }
    }

    function searchFilter(options: ResearchSearchItem[], state: FilterOptionsState<ResearchSearchItem>)
        : ResearchSearchItem[] {
        if (state.inputValue.length < 2) {
            return options;
        }
        const newOptions: ResearchSearchItem[] = [];
        options.forEach((element) => {
            if (element.displayName.toLowerCase().includes(state.inputValue.toLowerCase())
                && element.displayName !== NO_RESULTS_MESSAGE_TRANSLATED) {
                newOptions.push(element);
            }
        });

        if (newOptions.length === 0) {
            newOptions.push(new ResearchSearchItem(-1, NO_RESULTS_MESSAGE_TRANSLATED, "NOT_FOUND", "", undefined));
        }
        return newOptions;
    }

    function isScrolling() {
        return topOfSubNavY === 0;
    }

    async function showSearch() {
        await setSearchVisible(true);
        const parent = getElementById("autocomplete-container");
        (getElementsByTagName(parent, "input")[0] as HTMLElement).focus();
    }

    function setItemsToSearch() {
        if (props.searchItems) {
            const items = props.searchItems.slice(0).sort((a, b) => {
                return a.displayName.trim().toLowerCase().localeCompare(b.displayName.trim().toLowerCase());
            });
            items.push(new ResearchSearchItem(-1, NO_RESULTS_MESSAGE_TRANSLATED, "NOT_FOUND", "", undefined));

            setSearchItems(items);
        }
    }

    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: `${AonColors.AonBlack} !important`,
                        opacity: '0.4 !important'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderWidth: '0px 0px 2px 0px !important',
                        borderColor: `${AonColors.AonTealDark} !important`,
                        borderRadius: '0px !important',
                    }
                }
            }
        },
    });

    function renderSearchNav() {
        return <div className="search__input-box">
            <div id="autocomplete-container">
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        options={searchItems}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={getTranslation("subnav.search-managers-strategies",
                                    "Search managers or strategies")}/>}
                        className="search__search-field clickable"
                        getOptionLabel={(option: ResearchSearchItem) => option.displayName}
                        onChange={(event, value: ResearchSearchItem) => {
                            handleSearchItemSelected(value);
                        }}
                        onInputChange={(event, newValue) => {
                            setSearchText(newValue);
                        }}
                        fullWidth={true}
                        size="small"
                        filterOptions={searchFilter}
                        freeSolo={true}
                        selectOnFocus={true}
                        getOptionDisabled={(option) => option.backstopId === -1}
                        renderOption={(props:HTMLAttributes<HTMLLIElement>, option: ResearchSearchItem) => {
                            return <li {...props} key={`${option.backstopId}${option.displayName}`}>
                                {option.displayName}
                            </li>;
                        }}
                        data-testid="search__search-field"
                    />
                </ThemeProvider>
            </div>
        </div>;
    }

    useEffect(() => {
        setItemsToSearch();
        window.addEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {
        setItemsToSearch();
    }, [props]);

    return <div className={`sub-nav ${subNavScrolling()}`} data-testid="sub-nav__container">
        <div className="sub-nav__content">
            <div className="sub-nav__spacer"/>
            <div className="sub-nav__items" data-testid="sub-nav__items">
                {renderNavItems()}
            </div>
            <div className="sub-nav__search-container" data-testid="sub-nav__search-container">
                {props.searchEnabled ? renderSearch() : null}
            </div>
        </div>
    </div>;
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ISubNavPropsFromActions => {
    return {
        actions: bindActionCreators({
            selectTicker: productActions.selectTicker,
        }, dispatch),
    };
};

const SubNav = connect<any, ISubNavPropsFromActions, any>(undefined, mapDispatchToProps)(SubNavComponent);

export default SubNav;
