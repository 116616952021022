import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    clientReportingEnabled,
    cprPortfolioOverviewEnabled,
    hasAonTrustCompany,
    hasClientPortfolioAccess,
    hasClientReports, hasClientResearchEnabled,
    hasCprSharedMediaAdmin,
    hasPortfolioAccess,
    hasPowerbiReportAdmin,
    hasResearchAccess,
    insightsEnabled,
    isEditingPlanPortfolio,
    oddIqEnabled,
    oddReportsEnabled,
    performanceReportingEnabled,
    sharedDocumentsEnabled,
} from "../../../../mainReducerMapSelectors";
import {navigateTo} from "../../../../navigateTo";
import {getBrowserLocationPath} from "../../../api/BrowserWrapper";
import {isIE} from "../../../utils/browserUtil";
import {AllNavigationMenus, INavigationMenu, NavigationTab,} from "../../../utils/navigationUtils";
import {
    hasConsultantLevelAccess,
    hasPortfolioPageAccess, hasResearchPageAccess
} from "../../../utils/sessionUtil";
import {showMasterLectureSeries} from "../../../utils/planUtil";
import {ISessionState} from "../../session/SessionReducer";
import {Menu} from "../Menu.component";
import PlanList from "../PlanList.component";
import HeaderActions from "./HeaderActions";
import {handleEnter} from "../../../utils/commonUtil";
import {ICorsErrorModalOpen} from "../OktaLogout";
import {IApplicationRootState} from "../../../../applicationState";

export type IHeaderPropsFromState = ISessionState & {
    hasResearchAccess: boolean;
    showUsernameMenu: boolean;
    currentNavigationTab?: NavigationTab;
    editingPlanPortfolio: boolean;
    sharedDocumentsEnabled: boolean;
    oddIqEnabled: boolean;
    oddReportsEnabled: boolean;
    clientReportingEnabled: boolean;
    hasClientReports: boolean;
    performanceReportingEnabled: boolean;
    cprPortfolioOverviewEnabled: boolean;
    insightsEnabled: boolean;
    powerbiReportAdmin: boolean;
    cprMediaAdmin: boolean;
    aonTrustCompany: boolean;
    hasPortfolioAccess: boolean;
    clientPortfolioEnabled: boolean;
    hasClientResearchEnabled: boolean;
};

export const ATC_HEADER_LOGO_TEXT = "Aon Collective Investment Trust";

export type IHeaderProps = IHeaderPropsFromState & IHeaderPropsFromActions & ICorsErrorModalOpen;

export class Header extends React.Component<IHeaderProps> {

    private static currentTabName(hasPortfolioAccess: boolean): string | undefined {
        const excludeMenu = hasPortfolioAccess ? NavigationTab.MANAGER_RESEARCH : NavigationTab.PORTFOLIO;
        const navigationMenu = AllNavigationMenus
            .filter((navMenu: INavigationMenu) => navMenu.id !== excludeMenu.toString())
            .find((navMenu: INavigationMenu) => this.browserLocationMatchesPath(navMenu));

        return (navigationMenu) ? navigationMenu.id : undefined;
    }

    private static browserLocationMatchesPath(navMenu: INavigationMenu) {
        const currentUri = getBrowserLocationPath().split("/")[1];
        return navMenu.paths.some((path) => currentUri === path);
    }

    public componentDidMount() {
        if (this.props.actions) {
            this.props.actions.setMenuVisibility(false);
        }
    }

    public componentDidUpdate() {
        this.selectNavigationTab(Header.currentTabName(this.props.hasPortfolioAccess));
    }

    public render() {
        return <header id="header-main" className={this.getHeaderClasses()} data-testid="header-main">
                <div className="header__main--hexagons"/>
                <div className="header__content max-width-1600">
                    <Link to="/home" id="aon-logo" tabIndex={0}>
                        {this.renderHeaderText(this.props.aonTrustCompany)}
                    </Link>
                    {!this.props.aonTrustCompany && this.renderTabs()}
                    <div className="header__right">
                        <div>
                            <div className="header-bar__menu-items">
                                <PlanList {...{
                                    planList: this.props.plans,
                                    currentPlan: this.props.currentPlan!,
                                }} />
                                <a
                                    className="header-bar__user-icon fa-light fa-user"
                                    tabIndex={0}
                                    onClick={() => this.openUsernameMenu()}
                                    onKeyUp={handleEnter(() => this.openUsernameMenu())}
                                    aria-label="User slide out window"
                                />
                                <Menu username={this.props.username}
                                      shown={this.props.showUsernameMenu}
                                      closeMenuFn={() => {
                                          this.props.actions!.setMenuVisibility(false);
                                      }}
                                      userType={this.props.userType}
                                      setCorsErrorModalOpen={this.props.setCorsErrorModalOpen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </header>;
    }

    private renderTabs() {
        const style = isIE() ? {minWidth: 700} : {};
        return <div className="header__left" style={style} data-testid="header__left">
            <div className="navigation-tabs">
                {this.getTabElements()}
            </div>
        </div>;
    }

    private renderHeaderText(aonTrustCompany: boolean) {
        return aonTrustCompany
        ? <div className="header__atc-logo-text">{ATC_HEADER_LOGO_TEXT}</div>
        : <div className="header__aon-logo-text">Aon Iris</div>;
    }

    private getTabElements() {
        const navigate = (path: any, value: any) => {
            navigateTo(path);
            this.props.actions!.setCurrentNavigationTab(value);
        };

        return AllNavigationMenus
            .filter(this.hasPortfolioAccess)
            .filter(this.hasResearchAccess)
            .filter(this.hasSharedDocumentsAccess)
            .filter(this.hasInsightsAccess)
            .filter(this.hasMasterLectureSeriesAccess)
            .filter(this.hasMasterLectureSeriesAccess)
            .filter(this.hasOddIqAccess)
            .filter(this.hasOddReportsAccess)
            .filter(this.hasClientReportingAccess)
            .filter(this.hasPowerbiReportAdminAccess)
            .filter(this.hasCprSharedMediaAdminAccess)
            .map(({id, label, elementId, path}) => {
                const className =
                    `navigation-tab old-anchor clickable${(this.props.currentNavigationTab?.toString() === id) ? " selected" : ""}`;
                    return <a key={elementId}
                              id={elementId}
                              tabIndex={0}
                              onClick={() => navigate(path, id)}
                              onKeyUp={handleEnter(() => navigate(path, id))}
                              className={className}>
                        <FormattedMessage id={`header.${id}`}
                                          defaultMessage={label}/>
                    </a>;
                },
            );
    }

    private hasPortfolioAccess = (tab: INavigationMenu) =>
        hasPortfolioPageAccess(this.props.hasPortfolioAccess, this.props.clientPortfolioEnabled)
        || tab.id !== NavigationTab.PORTFOLIO.toString();

    private hasResearchAccess = (tab: INavigationMenu) =>
        hasResearchPageAccess(
            this.props.hasResearchAccess, this.props.hasPortfolioAccess, this.props.clientPortfolioEnabled)
        || tab.id !== NavigationTab.MANAGER_RESEARCH.toString();

    private hasSharedDocumentsAccess = (tab: INavigationMenu) => {
        return (this.props.sharedDocumentsEnabled && !this.props.aonTrustCompany)
            || tab.id !== NavigationTab.SHARED_DOCUMENTS.toString();
    };

    private hasPowerbiReportAdminAccess = (tab: INavigationMenu) => {
        return this.props.powerbiReportAdmin && hasConsultantLevelAccess()
            || tab.id !== NavigationTab.POWERBI_REPORT_ADMIN.toString();
    };

    private hasCprSharedMediaAdminAccess = (tab: INavigationMenu) => {
        return this.props.cprMediaAdmin && hasConsultantLevelAccess()
            || tab.id !== NavigationTab.CPR_SHARED_MEDIA_ADMIN.toString();
    };

    private hasInsightsAccess = (tab: INavigationMenu) =>
        this.props.insightsEnabled || tab.id !== NavigationTab.INSIGHTS.toString();

    private hasMasterLectureSeriesAccess = (tab: INavigationMenu) => {
        return showMasterLectureSeries() || tab.id !== NavigationTab.MASTER_LECTURE_SERIES.toString();
    };

    private hasOddIqAccess = (tab: INavigationMenu) => this.props.oddIqEnabled
        || tab.id !== NavigationTab.ODD_IQ.toString();


    private hasClientReportingAccess = (tab: INavigationMenu) => {
        return ((this.props.clientReportingEnabled || hasConsultantLevelAccess())
            && (this.props.performanceReportingEnabled || this.props.hasClientReports))
            || tab.id !== NavigationTab.CLIENT_REPORTING.toString();
    };

    private hasOddReportsAccess = (tab: INavigationMenu) =>
        this.props.oddReportsEnabled || tab.id !== NavigationTab.ODD_REPORTS.toString();

    private getHeaderClasses() {
        return `main__header 
            ${this.props.editingPlanPortfolio && "header__edit-portfolio-mode"} 
            ${this.props.aonTrustCompany && "header__atc-plan"}`;
    }

    private selectNavigationTab(tabName: string | undefined) {
        if (tabName) {
            this.props.actions!.setCurrentNavigationTab(tabName);
        } else {
            this.props.actions!.clearCurrentNavigationTab();
        }
    }

    private openUsernameMenu() {
        this.props.actions!.setMenuVisibility(true);
    }
}

export const mapStateToProps = (state: IApplicationRootState): IHeaderPropsFromState => {
    return {
        ...state.headerRootState!,
        ...state.session!,
        hasResearchAccess: hasResearchAccess(state),
        editingPlanPortfolio: isEditingPlanPortfolio(state),
        sharedDocumentsEnabled: sharedDocumentsEnabled(state),
        oddIqEnabled: oddIqEnabled(state),
        oddReportsEnabled: oddReportsEnabled(state),
        clientReportingEnabled: clientReportingEnabled(state),
        hasClientReports: hasClientReports(state),
        performanceReportingEnabled: performanceReportingEnabled(state),
        cprPortfolioOverviewEnabled: cprPortfolioOverviewEnabled(state),
        insightsEnabled: insightsEnabled(state),
        powerbiReportAdmin: hasPowerbiReportAdmin(state),
        cprMediaAdmin: hasCprSharedMediaAdmin(state),
        aonTrustCompany: hasAonTrustCompany(state),
        hasPortfolioAccess: hasPortfolioAccess(state),
        clientPortfolioEnabled: hasClientPortfolioAccess(state),
        hasClientResearchEnabled: hasClientResearchEnabled(state),
    };
};

export interface IHeaderPropsFromActions {
    actions?: typeof HeaderActions;
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IHeaderPropsFromActions => {
    return {
        actions: bindActionCreators({...HeaderActions}, dispatch),
    };
};

const connectedComponent = connect<IHeaderPropsFromState, IHeaderPropsFromActions>
(mapStateToProps, mapDispatchToProps)(Header);

export default connectedComponent;
