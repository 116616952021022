import * as React from "react";
import {ReactNode, useEffect} from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    hasAonTrustCompany,
    hasClientPortfolioAccess,
    hasClientResearchEnabled, hasPortfolioAccess,
    hasResearchAccess, insightsEnabled,
    planCountryName,
    planSettingsAreAvailable,
} from "../../../mainReducerMapSelectors";
import {showHomePageMessage} from "../../utils/envUtil";
import {PortfolioMenu, ResearchMenu, ThoughtLeadershipMenu} from "../../utils/navigationUtils";
import {HomePageMessageNotification} from "../home-page/HomePageMessageNotification";
import ResearchNav from "../research/ResearchNav.component";
import rootActions from "../root/rootActions";
import sharedDocumentsActions from "../shared-documents/SharedDocuments.actions";
import {ISharedDocumentsFileUploadProps} from "../shared-documents/SharedDocuments.reducer";
import {ThoughtLeadershipNav} from "../thought-leadership/ThoughtLeadershipNav.component";
import Header from "./header/Header.component";
import {INotificationObject, NotificationTypes} from "./header/HeaderReducer";
import {PowerBiExportNotification} from "../power-bi-reporting/PowerBiExportNotification";
import {ICorsErrorModalOpen} from "./OktaLogout";
import {IApplicationRootState} from "../../../applicationState";
import {PortfolioNav} from "../portfolio/PortfolioNav.component";
import {
    hasPortfolioPageAccess, hasResearchPageAccess
} from "../../utils/sessionUtil";

interface ILayoutHeaderPropsFromParent {
    children?: ReactNode;
    actions: typeof rootActions & typeof sharedDocumentsActions;
}

interface ILayoutHeaderPropsFromState {
    planSettingsAreAvailable: boolean;
    hasResearchAccess: boolean;
    pathname: string;
    hasClientResearchEnabled: boolean;
    fileUpload: ISharedDocumentsFileUploadProps;
    notificationObject?: INotificationObject;
    planCountryName: string;
    hasPortfolioAccess: boolean;
    clientPortfolioEnabled: boolean;
    insightsEnabled: boolean;
    aonTrustCompany: boolean;
}

export type ILayoutHeaderProps =
    ILayoutHeaderPropsFromParent &
    ILayoutHeaderPropsFromState &
    ICorsErrorModalOpen;

export const LayoutHeaderAndBody: React.FunctionComponent<ILayoutHeaderProps> = (props) => {

    function renderHomePageMessage() {
        const homePageMessage = showHomePageMessage();

        if (!homePageMessage) {
            return null;
        }

        return <HomePageMessageNotification
            message={homePageMessage}
        />;
    }

    function renderSubNav() {
        if (props.aonTrustCompany) {
            return null;
        }
        const navigationComponent = getSubNavComponent();
        return navigationComponent ? navigationComponent.subNav : <div/>;
    }

    function renderSubNavSpacer() {
        if (props.aonTrustCompany) {
            return null;
        }
        return getSubNavComponent() ? <div className="sub-nav__vertical-spacer"/> : <div/>;
    }

    function getSubNavComponent() {
        const subNavigation = [
            {shouldRender: shouldRenderThoughtLeadershipNav, subNav: <ThoughtLeadershipNav/>},
            {shouldRender: shouldRenderResearchNav, subNav: <ResearchNav/>},
            {shouldRender: shouldRenderPortfolioNav, subNav: <PortfolioNav/>},
        ];

        return subNavigation.filter((it) => it.shouldRender())[0];
    }

    function shouldRenderResearchNav() {
        return hasResearchPageAccess(props.hasResearchAccess, props.hasPortfolioAccess, props.clientPortfolioEnabled)
            && ResearchMenu.paths.map((it) => pathContainsValue(it)).some((it) => it);
    }

    function shouldRenderPortfolioNav() {
        return hasPortfolioPageAccess(props.hasPortfolioAccess, props.clientPortfolioEnabled)
            && PortfolioMenu.paths.map((it) => pathContainsValue(it)).some((it) => it);
    }

    function shouldRenderThoughtLeadershipNav() {
        return props.insightsEnabled && ThoughtLeadershipMenu.paths.map((it) => pathContainsValue(it)).some((it) => it);
    }

    function pathContainsValue(value: string) {
        return props.pathname.includes(value);
    }

    function renderNotificationMessage() {
        if (!props.notificationObject) {
            return null;
        }
        switch (props.notificationObject!.notificationType) {
            case NotificationTypes.SUCCESS:
                return renderSuccessWithMessage();
            case NotificationTypes.FAILURE:
                return renderFailureWithMessage();
            default:
                break;
        }
    }

    function renderSuccessWithMessage() {
        return <div className="top-alert-notification top-alert--success">
            <div className="success-icon fas fa-circle-check"/>
            <div className="notification-message">{props.notificationObject!.message}</div>
        </div>;
    }

    function renderFailureWithMessage() {
        return <div className="top-alert-notification top-alert--failure">
            <div className="failure-icon fas fa-triangle-exclamation"/>
            <div className="notification-message">{props.notificationObject!.message}</div>
        </div>;
    }

    function renderFileUploadFailureAlert() {
        let className;
        if (props.fileUpload.success === undefined) {
            className = "top-alert top-alert--hidden";
        } else if (props.fileUpload.success) {
            className = "top-alert top-alert--success";
        } else if (!props.fileUpload.success) {
            className = "top-alert top-alert--failure";
        }

        return <div className={className}>
            {renderFileUploadAlertIcon()}
            <div className="file-upload-message">{props.fileUpload.message}</div>
            {renderCloseFileUploadFailureAlert()}
        </div>;
    }

    function renderCloseFileUploadFailureAlert() {
       return props.fileUpload.success === false
           ?  <div className="close-message-button" onClick={props.actions.clearFileUploadFailure}>✕</div>
           : null;
    }

    function renderFileUploadAlertIcon() {
        return props.fileUpload.success === false
        ? <div className="failure-icon fas fa-triangle-exclamation"/>
        : <div className="success-icon fas fa-circle-check "/>;
    }

    useEffect(() => {
        props.actions.requestPlanSettings();
    }, []);

    return props.planSettingsAreAvailable
        ? <div id="main-app" data-testid="main-app">
            <Header setCorsErrorModalOpen={props.setCorsErrorModalOpen}/>
            {props.pathname === "/home" ? renderHomePageMessage() : null}
            {props.pathname === "/home" ? <div className="home-top-spacer"/> : null}
            {renderSubNav()}
            {renderSubNavSpacer()}
            {renderFileUploadFailureAlert()}
            {renderNotificationMessage()}
            <PowerBiExportNotification delayDispatch={5000}/>
            <div className="main max-width-1600">
                {props.children}
            </div>
        </div>
        : <div/>;
};


export const mapStateToProps = (state: IApplicationRootState): ILayoutHeaderPropsFromState => {
    return {
        pathname: (state as any).router.location.pathname,
        fileUpload: state.sharedDocumentsRootState!.fileUpload,
        notificationObject: state.headerRootState!.notificationObject,
        planSettingsAreAvailable: planSettingsAreAvailable(state),
        planCountryName: planCountryName(state),
        hasResearchAccess: hasResearchAccess(state),
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasPortfolioAccess: hasPortfolioAccess(state),
        clientPortfolioEnabled: hasClientPortfolioAccess(state),
        insightsEnabled: insightsEnabled(state),
        aonTrustCompany: hasAonTrustCompany(state)
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    const actionCreators = {...rootActions, ...sharedDocumentsActions};
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
};

const connectedComponent = connect<any, any, any>(mapStateToProps, mapDispatchToProps)(LayoutHeaderAndBody);

export default connectedComponent;
