import {AxiosResponse} from "axios";
import {LANG} from "../components/common/intlUtils";
import {IPlanInfo} from "../model/ClientsAndPlans.model";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

export enum PLAN_TYPE {
    PUBLIC_DB = 1,
    CORPORATE_DB,
    DC,
    NON_PROFIT
}

export interface IPlanSettings {
    hasResearchAccess: boolean;
    emailEnabled: boolean;
    hasClientResearchEnabled: boolean;
    planCountryName: string;
    hasHoldings: boolean;
    hasClientReports: boolean;
    sharedDocumentsEnabled: boolean;
    oddIqEnabled: boolean;
    performanceReportingEnabled: boolean;
    cprPortfolioOverviewEnabled: boolean;
    clientReportingEnabled: boolean;
    clientPerformanceReportingAccess: boolean;
    languageId: number;
    planTypeId: number | null;
    investmentsEnabled: boolean;
    clientUploadsEnabled: boolean;
    oddReportsEnabled: boolean;
    hasProducts: boolean;
    insightsEnabled: boolean;
    thirdPartyAccess: boolean;
    powerbiReportAdmin: boolean;
    oddPowerBiEnabled: boolean;
    showFactSheets: boolean;
    cprMediaAdmin: boolean;
    aonTrustCompany: boolean;
    hasPortfolioAccess: boolean,
    clientPortfolioEnabled: boolean,
    hasDynamicResearchEnabled: boolean,
    hasAllAccessEnabled: boolean
}

export const planSettingsInitialState: IPlanSettings = {
    hasResearchAccess: false,
    emailEnabled: false,
    hasClientResearchEnabled: false,
    planCountryName: "",
    hasHoldings: false,
    hasClientReports: false,
    sharedDocumentsEnabled: false,
    oddIqEnabled: false,
    performanceReportingEnabled: false,
    cprPortfolioOverviewEnabled: false,
    clientReportingEnabled: false,
    clientPerformanceReportingAccess: false,
    languageId: LANG.ENGLISH,
    planTypeId: null,
    investmentsEnabled: false,
    clientUploadsEnabled: false,
    oddReportsEnabled: false,
    hasProducts: false,
    insightsEnabled: false,
    thirdPartyAccess: false,
    powerbiReportAdmin: false,
    oddPowerBiEnabled: false,
    showFactSheets: true,
    cprMediaAdmin: false,
    aonTrustCompany: false,
    hasPortfolioAccess: false,
    clientPortfolioEnabled: false,
    hasDynamicResearchEnabled: false,
    hasAllAccessEnabled: false
};

export class AuthApi {
    public static logout(): Promise<IApiResult<boolean>> {
        return ApiBase.processPost("/loggedout", null);
    }

    public static requestPlanSettings(): Promise<IApiResult<IPlanSettings>> {
        return ApiBase.processGet("/plans/current/settings", (axiosResponse: AxiosResponse): IPlanSettings => {
            return axiosResponse.data;
        });
    }

    public static plans(): Promise<IApiResult<IPlanInfo[]>> {
        return ApiBase.processGet("/plans", (axiosResponse: AxiosResponse): IPlanInfo[] => {
            return axiosResponse.data;
        });
    }

}
