import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {portfolioPlanTreeSelectedDate} from "./PortfolioMapSelectors";
import {IPlanTreeNodeFlat, IPlanTreeNodeFlatWithRating} from "../../model/Portfolio.model";
import {useEffect, useState} from "react";
import {ISelectValue} from "../common/Select.component";
import {compareDates, formatMonthYearDate, parseDate} from "../../utils/dateUtil";
import {PortfolioApi} from "../../api/PortfolioApi";
import {setTreeDateAction} from "./PortfolioActions";
import {NewSelectComponent} from "../common/NewSelect.component";
import {PlanTreeTable} from "./PlanTreeTable";
import {AssetAllocationDonutChart} from "./AssetAllocationDonutChart";
import {getPtcOfTotalPriorPeriod, getTotalPlanNode} from "./portfolioUtil";
import {formatCurrency} from "../../utils/numberUtil";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {ErrorComponent} from "../base/Error.component";
import {Map} from "immutable";
import {ProductSummary} from "../../model/product/ProductSummary";

export interface IAssetAllocationTabProps {
    productSummaries: Map<number, ProductSummary>;
}

export const AssetAllocationTab: React.FunctionComponent<IAssetAllocationTabProps> = (props) => {
    const dateOptionFromStore = useSelector(state => portfolioPlanTreeSelectedDate(state));

    const [nodes, setNodes] = React.useState<IPlanTreeNodeFlatWithRating[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const [currentDateOption, setCurrentDateOption] = useState<ISelectValue | undefined>(undefined);
    const [dateOptions, setDateOptions] = useState<ISelectValue[]>([]);

    const dispatch = useDispatch();

    const setDates = (nodes: IPlanTreeNodeFlat[]) => {
        const dates = Array.from(new Set(nodes
            .map((node) => node.date)))
            .sort((a, b) => compareDates(parseDate(a.toString()), parseDate(b.toString())));

        const dateOptions: ISelectValue[] = dates.map((date) => (
            {name: formatMonthYearDate(date), id: date})
        );
        setDateOptions(dateOptions);
        setCurrentDateOption(dateOptionFromStore ? dateOptionFromStore : dateOptions[0]);
    };

    const getRating = (backstopId: string): string => {
        const rating = props.productSummaries.get(Number(backstopId))?.currentRating;
        return rating || "";
    };

    const getNodes = (flatNodes: IPlanTreeNodeFlat[]) => {
        const ratingNodes = flatNodes.map((node: IPlanTreeNodeFlat): IPlanTreeNodeFlatWithRating => {
            return {
                ...node,
                rating: node.backstopId ? getRating(node.backstopId) : "",
                priorMarketValuePctOfTotal: getPtcOfTotalPriorPeriod(node, flatNodes)
            };
        });
        setNodes(ratingNodes);
    };

    useEffect(() => {
        PortfolioApi.getCurrentPlanPortfolio()
            .then((response) => {
                setDates(response);
                getNodes(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    }, []);

    useEffect(() => {
        if (currentDateOption) dispatch(setTreeDateAction(currentDateOption));
    }, [currentDateOption]);

    const handleDateChange = (e: any) => {
        setCurrentDateOption(dateOptions.find((date) => date.id === e.target.value));
    };

    const renderDateSelection = () => {
        return <div className="asset-allocation-tab__date-selection" data-testid="asset-allocation-tab__date-selection">
            <div className="asset-allocation-tab__date-label">As of:</div>
            <NewSelectComponent
                id={"asset-allocation-tab__dates"}
                values={dateOptions}
                selected={currentDateOption?.id}
                width={200}
                handleChange={handleDateChange}
                classNameSuffix="filter-menu_asset-allocation-tab-dates"
            />
        </div>;
    };

    const renderPlanTreeTable = () => {
        return currentDateOption &&
            <PlanTreeTable selectedDate={currentDateOption!} datedPlanNodes={nodes}/>;
    };

    const getChartContainerClass = (index: number) => {
        return index === 0 ? "asset-allocation-tab__chart-left" : "asset-allocation-tab__chart-right";
    };

    const renderAssetAllocationDonutCharts = () => {
        const selectedDateIndex = dateOptions.findIndex(date => date.id === currentDateOption?.id);

        return dateOptions
            .filter((_, index) => index === selectedDateIndex || index === selectedDateIndex + 1)
            .map((date, index) => (
                <div key={index} className={getChartContainerClass(index)}
                     data-testid="asset-allocation-tab__chart-container">
                    <AssetAllocationDonutChart selectedDate={date} dataNodes={nodes} key={`donut-chart-${index}`}
                                               titlePrefix={index === 0 ? "Total" : "Prior Period"}/>
                </div>
            ));
    };

    const renderTotalAssets = () => {
        const totalNode = getTotalPlanNode(nodes, currentDateOption?.id as string);
        return <div data-testid="asset-allocation-tab__total-assets" className="asset-allocation-tab__total-assets">
            {totalNode === undefined ? "" : formatCurrency(totalNode!.marketValue, 0)}
        </div>;
    };

    const renderContent = () => {
        return <div className="asset-allocation-tab__container"
                    data-testid={"asset-allocation-tab__container"}>
            {renderDateSelection()}
            <div className={"asset-allocation-tab__charts-container"}>
                <div className="asset-allocation-tab__charts">{renderAssetAllocationDonutCharts()}</div>
            </div>
            <div className="asset-allocation-tab__table-container">
                <div className="asset-allocation-tab__title-selection">
                    <div>
                        <div className="asset-allocation-tab__title-label">
                            <h3>Total Market Value</h3>
                            <p className="asset-allocation-tab__title-sub-label"
                               data-testid="asset-allocation-tab__title-sub-label">
                                {`as of ${currentDateOption?.name}`}
                            </p>
                        </div>
                        {renderTotalAssets()}
                    </div>
                </div>
                {renderPlanTreeTable()}
            </div>
        </div>;
    };

    return loading ? <LoadingSpinner/> : error ? <ErrorComponent/> : renderContent();
};