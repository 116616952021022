import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {IPlanTreeNodeFlat} from "../model/Portfolio.model";
import {IRangeValue} from "./InFormReportingApi";


export class PortfolioApi {
    public static getCurrentPlanPortfolio(): Promise<IPlanTreeNodeFlat[]> {
        return ApiBase.processGetUnwrapped("/plans/current/portfolio/plan-tree", (response: AxiosResponse) => {
            return response.data;
        });
    }

    public static getCurrentPlanMarketValues(): Promise<IRangeValue[]> {
        return ApiBase.processGetUnwrapped("/plans/current/portfolio/market-values", (response: AxiosResponse) => {
            return response.data;
        });
    }
}