import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {ProductSummary} from "../../model/product/ProductSummary";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {CheckBoxComponent} from "../common/CheckBox.component";
import {LANG} from "../common/intlUtils";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import adminActions from "./adminActions";
import {COUNTRY_SELECT_VALUES} from "./AdminAllClients";
import {scrollToElement} from "../../utils/browserUtil";
import {IPlanAuthorization} from "../../model/PlanAuthorization.model";
import {AdminCreatePlanResearchComponent} from "./AdminCreatePlanResearch.component";
import {AssetClass, AssetClassMapperReverse} from "../research/research-filters/FilterAndSort.menuItems";
import {PLAN_TYPE} from "../../api/AuthApi";

export interface IEditPlan {
    plan: IPlanAuthorization;
    planId: number;
    clients: ISelectValue[];
}

export interface IAdminCreatePlanComponent {
    products: ImmutableMap<number, ProductSummary>;
    selectedClientId: number | null;
    onCancel: () => void;
    hasPortfolioAccess: boolean;
    editPlan?: IEditPlan;
}

export const LANGUAGE_SELECT_VALUES: ISelectValue[] = [
    {id: LANG.ENGLISH, name: "English"},
    {id: LANG.FRENCH, name: "French"},
    {id: LANG.GERMAN, name: "German"},
];

export const PLAN_TYPE_VALUES: ISelectValue[] = [
    {name: "Public DB", id: PLAN_TYPE.PUBLIC_DB},
    {name: "Corporate DB", id: PLAN_TYPE.CORPORATE_DB},
    {name: "DC", id: PLAN_TYPE.DC},
    {name: "Non Profit", id: PLAN_TYPE.NON_PROFIT},
];

export const AdminCreatePlanComponent: React.FunctionComponent<IAdminCreatePlanComponent> = (props) => {
    const initialProducts: ProductSummary[] = props.editPlan && props.editPlan.plan.products.length > 0
        ? props.products.filter((product) =>
            props.editPlan!.plan.products.indexOf(product.backstopId) > -1).valueSeq().toArray()
        : [];


    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [planName, setPlanName] = useState<string>(props.editPlan ? props.editPlan.plan.name : "");
    const [powerBiId, setPowerBiId] = useState<string>(props.editPlan && props.editPlan.plan.powerbiId ?
        props.editPlan.plan.powerbiId.toString() : "");
    const [oddPowerBiId, setOddPowerBiId] = useState<string>(props.editPlan && props.editPlan.plan.oddPowerbiId
        ? props.editPlan.plan.oddPowerbiId : "");
    const [allAccess, setAllAccess] = useState<boolean>(props.editPlan ? props.editPlan.plan.allAccess : false);

    const [selectedProducts, setSelectedProducts] = useState<ProductSummary[]>(initialProducts);


    const [selectedCountryId, setSelectedCountryId] = useState<number | undefined>(
        props.editPlan ? props.editPlan.plan.countryId : undefined);
    const [selectedLanguage, setSelectedLanguage] = useState<number | undefined>(
        props.editPlan ? props.editPlan.plan.languageId : undefined);
    const [selectedPlanType, setSelectedPlanType] = useState<number | undefined>(
        props.editPlan ? props.editPlan.plan.planTypeId : undefined);
    const [clientResearchEnabled, setClientResearchEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.clientResearchEnabled : false);
    const [clientPortfolioEnabled, setClientPortfolioEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.clientPortfolioEnabled : false);
    const [sharedDocsEnabled, setSharedDocsEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.sharedDocumentsEnabled : false);
    const [oddEnabled, setOddEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.oddIqEnabled : false);
    const [performanceReportingEnabled, setPerformanceReportingEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.performanceReportingEnabled : false);
    const [cprPortfolioOverviewEnabled, setCprPortfolioOverviewEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.cprPortfolioOverviewEnabled : false);
    const [clientUploadEnabled, setClientUploadEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.clientUploadsEnabled : false);
    const [investmentsEnabled, setInvestmentsEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.investmentsEnabled : false);
    const [clientReportingEnabled, setClientReportingEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.clientReportingEnabled : false);
    const [oddReportsEnabled, setOddReportsEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.oddReportsEnabled : false);
    const [insightsEnabled, setInsightsEnabled] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.insightsEnabled : false);
    const [client, setClient] = useState<ISelectValue | undefined>(
        props.editPlan ?
            props.editPlan.clients.find((client) => client.id === props.editPlan!.plan.clientId)
            : undefined
    );
    const [oddAllAccess, setOddAllAccess] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.oddAllAccess : false);
    const [thirdPartyAccess, setThirdPartyAccess] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.thirdPartyAccess : false);
    const [powerbiReportAdmin, setPowerbiReportAdmin] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.powerbiReportAdmin : false);
    const [oddProducts, setOddProducts] = useState<string[] | undefined>(
        props.editPlan ? props.editPlan.plan.oddProducts : []
    );
    const [cprSharedMediaAdmin, setCprSharedMediaAdmin] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.cprMediaAdmin : false);
    const [aonTrustCompany, setAonTrustCompany] = useState<boolean>(
        props.editPlan ? props.editPlan.plan.aonTrustCompany : false);

    const [dynamicResearchEnabled, setDynamicResearchEnabled] = useState<boolean>(
        props.editPlan
            ? props.editPlan.plan.planResearchAuthorization ? true : false
            : false);
    const [fundamentallyRatedOnly, setFundamentallyRatedOnly] = useState<boolean>(
        props.editPlan
            ? props.editPlan.plan.planResearchAuthorization
                ? props.editPlan.plan.planResearchAuthorization.fundamentallyRatedOnly : false
            : false);
    const [buyRatedOnly, setBuyRatedOnly] = useState<boolean>(
        props.editPlan
            ? props.editPlan.plan.planResearchAuthorization
                ? props.editPlan.plan.planResearchAuthorization.buyRatedOnly : false
            : false);
    const [assetClasses, setAssetClasses] = useState<string[]>(
        props.editPlan
            ? props.editPlan.plan.planResearchAuthorization
                ? props.editPlan.plan.planResearchAuthorization.assetClasses : []
            : []);
    const dispatch = useDispatch();

    const renderAddNewHeader = () => {
        return props.editPlan ? null : <h2 className="admin-add-client__container">Add New Plan</h2>;
    };

    const renderClientsDropdown = () => {
        return props.editPlan
            ?
            <div className="admin-client-plan__client-container">
                <div className="admin-client-plan__label">Client</div>
                <div className="admin-client-plan__client-select-container">
                    <SelectComponentReact
                        className="admin-client-plan__clients-dropdown"
                        menuIsOpen={undefined}
                        id={"clients-dropdown"}
                        options={props.editPlan.clients}
                        value={client}
                        onChange={setClient}
                        submitClicked={false}
                        getOptionLabel={(option: ISelectValue) => option.name}
                        getOptionValue={(option: ISelectValue) => option.id!.toString(10)}
                        renderRequired={false}
                        placeholder="Select Client"
                        stylesObject={{width: "100%"}}
                    />
                </div>
            </div>
            : null;
    };

    return <div className="admin-add-client__container" data-testid="admin-create-plan__container">
        {renderAddNewHeader()}
        <div className="admin-add-client__input-fields">
            <div className="admin-client-plan__label">Plan Name</div>
            {renderPlanNameTextField()}
            {renderClientsDropdown()}
            <br/>
            {renderCountriesDropdown()}
            <br/>
            {renderLanguageDropdown()}
            <br/>
            {renderPlanTypeDropdown()}
            <br/>
            {!props.hasPortfolioAccess && <AdminCreatePlanResearchComponent
                products={props.products}
                submitClicked={submitClicked}
                allAccess={allAccess}
                setAllAccess={setAllAccess}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                fundamentallyRatedOnly={fundamentallyRatedOnly}
                setFundamentallyRatedOnly={setFundamentallyRatedOnly}
                buyRatedOnly={buyRatedOnly}
                setBuyRatedOnly={setBuyRatedOnly}
                dynamicResearchEnabled={dynamicResearchEnabled}
                setDynamicResearchEnabled={setDynamicResearchEnabled}
                assetClasses={assetClasses}
                setAssetClasses={setAssetClasses}
            />}
            <div className="small bold">Select areas to enable for plan.</div>
            {renderResearchEnabledCheckbox()}
            {renderPortfolioEnabledCheckbox()}
            {renderSharedDocumentsEnabledCheckbox()}
            {renderInsightsEnabledCheckbox()}
            {renderClientReportingEnabledCheckbox()}
            {renderPowerBiIdField()}
            {renderPerformanceReportingEnabledCheckbox()}
            {renderCprPortfolioOverviewEnabledCheckbox()}
            {renderClientUploadEnabledCheckbox()}
            {renderThirdPartyAccessCheckbox()}
            {renderInvestmentsEnabledCheckbox()}
            {renderOddReportsEnabledCheckbox()}
            {renderOddEnabledCheckbox()}
            {renderOddPowerBiIdField()}
            {renderOddAllAccessCheckbox()}
            {renderOddProductsField()}
            {renderPowerbiReportAdminCheckbox()}
            {renderCPRSharedMediaAdminCheckbox()}
            {renderAonTrustCompanyCheckbox()}
        </div>
        <div className="admin-add-client__buttons">
            {renderCancelButton()}
            <div className="buttons-spacer-between"/>
            {renderSaveButton()}
        </div>
    </div>;

    function renderCountriesDropdown() {
        const handleChange = (e: any) => {
            return setSelectedCountryId(e.target.value);
        };

        return <div>
            <div className="admin-client-plan__label">Country</div>
            <SelectComponent
                id="admin-create-plan__countries"
                values={COUNTRY_SELECT_VALUES}
                selected={selectedCountryId}
                width={280}
                handleChange={handleChange}
                placeholder="Select Country..."
                renderRequired={true}
                submitClicked={submitClicked}
            />
        </div>;
    }

    function renderLanguageDropdown() {
        const handleChange = (e: any) => {
            return setSelectedLanguage(e.target.value);
        };

        return <div>
            <div className="admin-client-plan__label">Language</div>
            <SelectComponent
                id="admin-create-plan__languages"
                values={LANGUAGE_SELECT_VALUES}
                selected={selectedLanguage}
                width={280}
                handleChange={handleChange}
                placeholder="Select Language..."
                renderRequired={true}
                submitClicked={submitClicked}
            />
        </div>;
    }

    function renderPlanTypeDropdown() {
        const handleChange = (e: any) => {
            return setSelectedPlanType(e.target.value);
        };

        return <div>
            <div className="admin-client-plan__label">Plan Type</div>
            <SelectComponent
                id="admin-create-plan__plan-type"
                values={PLAN_TYPE_VALUES}
                selected={selectedPlanType}
                width={280}
                handleChange={handleChange}
                placeholder="Select Plan Type..."
                renderRequired={false}
                submitClicked={submitClicked}
            />
        </div>;
    }

    function renderPlanNameTextField() {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setPlanName(e.target.value);
        };

        return renderTextField("plan_name", planName,
            "admin-create-plan__plan-name-field",
            "Plan Name",
            handleChange,
            true);
    }

    function renderTextField(
        name: string,
        value: string,
        className: string,
        placeholder: string,
        handleChange: (e: any) => void,
        renderRequired: boolean) {

        return <TextFieldComponent
            name={name}
            className={className}
            type="text"
            fullWidth={true}
            value={value}
            handleChange={handleChange}
            placeholder={placeholder}
            renderRequired={renderRequired}
            submitClicked={submitClicked}
        />;
    }

    function renderOddAllAccessCheckbox() {
        return oddEnabled
        ? renderCheckbox("ODD IQ All Access Enabled", "odd-all-access", oddAllAccess, setOddAllAccess)
            : null;
    }

    function renderThirdPartyAccessCheckbox() {
        return renderCheckbox("Third Party Access Enabled", "third-party-access", thirdPartyAccess,
            setThirdPartyAccess);
    }

    function renderPowerbiReportAdminCheckbox() {
        return renderCheckbox("PowerBi Report Admin Enabled", "powerbi-report-admin", powerbiReportAdmin,
            setPowerbiReportAdmin);
    }
    function renderCPRSharedMediaAdminCheckbox() {
        return renderCheckbox("CPR Shared Media Admin Enabled", "cpr-shared-media-admin", cprSharedMediaAdmin,
            setCprSharedMediaAdmin);
    }

    function renderAonTrustCompanyCheckbox() {
        return renderCheckbox("Aon Trust Company", "aon-trust-company", aonTrustCompany,
            setAonTrustCompany);
    }

    function renderResearchEnabledCheckbox() {
        return renderCheckbox("Research for Client Users Enabled", "client-research-enabled", clientResearchEnabled, setClientResearchEnabled);
    }

    function renderPortfolioEnabledCheckbox() {
        return renderCheckbox("Portfolio Enabled for Client Users", "client-portfolio-enabled", clientPortfolioEnabled, setClientPortfolioEnabled);
    }

    function renderSharedDocumentsEnabledCheckbox() {
        return renderCheckbox("Shared Documents Enabled", "shared-docs", sharedDocsEnabled, (stateValue) => {
            setClientUploadEnabled(false);
            return setSharedDocsEnabled(stateValue);
        });
    }

    function renderInsightsEnabledCheckbox() {
        return renderCheckbox("Insights Enabled", "insights", insightsEnabled, (stateValue) => {
            setClientUploadEnabled(false);
            return setInsightsEnabled(stateValue);
        });
    }

    function renderOddEnabledCheckbox() {
        return renderCheckbox("ODD IQ Enabled", "odd", oddEnabled, setOddEnabled);
    }

    function renderPerformanceReportingEnabledCheckbox() {
        return renderCheckbox("Performance Reporting for Client Users Enabled",
            "performance-reporting",
            performanceReportingEnabled,
            setPerformanceReportingEnabled);
    }

    function renderCprPortfolioOverviewEnabledCheckbox() {
        return renderCheckbox("CPR Portfolio Overview for Clients Enabled",
            "cpr-portfolio-overview",
            cprPortfolioOverviewEnabled,
            setCprPortfolioOverviewEnabled);
    }

    function renderInvestmentsEnabledCheckbox() {
        return renderCheckbox("Investments Enabled for Client Users",
            "investments",
            investmentsEnabled,
            setInvestmentsEnabled);
    }

    function renderClientReportingEnabledCheckbox() {
        return renderCheckbox("Client Reporting for Client Users Enabled",
            "client-reporting",
            clientReportingEnabled,
            setClientReportingEnabled);
    }

    function renderOddReportsEnabledCheckbox() {
        return renderCheckbox("ODD Reports Enabled",
            "odd-reports",
            oddReportsEnabled,
            setOddReportsEnabled);
    }

    function renderPowerBiIdField() {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setPowerBiId(e.target.value);
        };

        return renderTextField("powerbi_id", powerBiId,
            "admin-create-plan__powerbi-id-field",
            "Performance Reporting PowerBI ID",
            handleChange, performanceReportingEnabled);
    }

    function renderOddPowerBiIdField() {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setOddPowerBiId(e.target.value);
        };

        if (oddEnabled) {
            return renderTextField(
                "odd_powerbi_id",
                oddPowerBiId,
                "admin-create-plan__odd-powerbi-id-field",
                "ODD IQ PowerBI ID",
                handleChange,
                false);
        } else {
            return;
        }
    }

    function sanitizeOddProductsString(value: string) {
        return value.trim()
            .replace(" ", "")
            .replace(" ", "")
            .replace("\"", "")
            .replace("'", "");
    }

    function renderOddProductsField() {
        const handleChange = (e: any) => {
            const newOddProductsString = sanitizeOddProductsString(e.target.value);
            setOddProducts(
                newOddProductsString.length > 0
                ? newOddProductsString.split(",")
                : undefined);
        };

        return oddEnabled && !props.hasPortfolioAccess
            ? <div className="admin-create-plan__odd-products-container"
               data-testid="admin-create-plan__odd-products-container">
                {renderTextField(
            "odd_products",
                oddProducts ? oddProducts.join(",") : "",
                "admin-create-plan__odd-products-field",
                "ODD IQ Product Access",
                handleChange,
                false)}
                <div className="admin-create-plan__odd-products-instructions">
                    ODD product IDs need to be comma separated, eg. ODD-12345,ODD-453467
                </div>
            </div>
            : null;
    }

    function renderClientUploadEnabledCheckbox() {
        return sharedDocsEnabled
            ? renderCheckbox(
                "Document Upload for Client Users Enabled", "client-upload", clientUploadEnabled, setClientUploadEnabled
            )
            : null;
    }

    function renderCheckbox(
        label: string,
        name: string,
        stateElement: boolean,
        setStateElement: React.Dispatch<React.SetStateAction<boolean>>) {

        return <CheckBoxComponent
            name={name}
            checked={stateElement}
            onChange={() => setStateElement(!stateElement)}
            label={label}
        />;
    }

    function renderSaveButton() {
        const isInvalidInput = () => {
            return (
                planName === ""
                || !props.selectedClientId
                || !selectedCountryId
                || !selectedLanguage
                || performanceReportingEnabled && powerBiId === ""
            );
        };

        const handleSave = () => {
            setSubmitClicked(false);
            if (isInvalidInput()) {
                setSubmitClicked(true);
                dispatch(setHeaderNotification(
                    {message: "Invalid input", notificationType: NotificationTypes.FAILURE},
                    5000));
                return;
            }

            const plan: IPlanAuthorization = {
                name: planName,
                clientId: props.editPlan ? client!.id as number : props.selectedClientId!,
                countryId: selectedCountryId!,
                allAccess: dynamicResearchEnabled ? false : allAccess,
                products: allAccess || dynamicResearchEnabled ? [] : selectedProducts.map((p) => p.backstopId),
                clientResearchEnabled,
                clientPortfolioEnabled,
                oddIqEnabled: oddEnabled,
                sharedDocumentsEnabled: sharedDocsEnabled,
                performanceReportingEnabled,
                cprPortfolioOverviewEnabled,
                investmentsEnabled,
                clientReportingEnabled,
                oddReportsEnabled,
                languageId: selectedLanguage!,
                planTypeId: selectedPlanType,
                powerbiId: powerBiId,
                oddPowerbiId: (oddEnabled) ?  oddPowerBiId : "",
                clientUploadsEnabled: clientUploadEnabled,
                insightsEnabled,
                oddAllAccess,
                oddProducts: oddProducts ? oddProducts : [],
                thirdPartyAccess,
                powerbiReportAdmin,
                cprMediaAdmin: cprSharedMediaAdmin,
                aonTrustCompany,
                planResearchAuthorization: dynamicResearchEnabled ? {
                    assetClasses: assetClasses.map(it => AssetClassMapperReverse[it as AssetClass]),
                    fundamentallyRatedOnly,
                    buyRatedOnly,
                } : undefined,
            };

            if (props.editPlan) {
                dispatch(adminActions.updatePlan(props.editPlan.planId, plan));
            } else {
                dispatch(adminActions.savePlan(plan));
            }
            scrollToElement(".main__header", 0);

            setSubmitClicked(false);
        };

        return <RaisedButton className="admin-create-plan__save-button"
                             style={prominentButtonStyle}
                             primary={true}
                             onClick={handleSave}>
            Save Plan
        </RaisedButton>;
    }

    function renderCancelButton() {
        return <RaisedButton className="admin-create-plan__cancel-button"
                             style={secondaryButtonStyle}
                             primary={false}
                             onClick={props.onCancel}>
            Cancel
        </RaisedButton>;
    }
};
