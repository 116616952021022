import {AxiosResponse} from "axios";
import {SharedDocument, SharedDocumentType} from "../model/SharedDocument.model";
import {formatNumericDate, parseDate} from "../utils/dateUtil";
import {getConfig} from "../utils/envUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

export class SharedDocumentApi {
    private static readDocument(data: any) {
        return new SharedDocument(
            data.id,
            data.fileName,
            data.title,
            parseDate(data.date),
            data.documentType,
            data.createdMillis,
            data.planIds,
            data.email,
            data.thirdPartyAccess);
    }

    public static uploadSharedDocumentMultiPlans(formData: FormData): Promise<IApiResult<number>> {
        return ApiBase.processPostWithResponseBody("/documents", formData, (response: AxiosResponse) => {
            return response.data;
        });
    }

    public static updateSharedDocument(document: SharedDocument): Promise<IApiResult<boolean>> {
        const data = {
            id: document.id,
            title: document.title,
            date: formatNumericDate(document.date),
            documentTypeId: document.documentType.id,
            planIds: document.planIds,
            thirdPartyAccess: document.thirdPartyAccess
        };
        return ApiBase.processPost(`/documents/${document.id}`, data);
    }

    public static deleteSharedDocument(documentId: number) {
        return ApiBase.processDelete(`/documents/${documentId}`);
    }

    public static sendEmail(
        documentIds: number[],
        userIds: number[],
        downloadBaseUrl: string,
        message: string,
        subject: string,
        postedByMessage: string
    ):
        Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/file-upload-emails`,
            {documentIds, userIds, downloadBaseUrl, message, subject, postedByMessage});
    }

    public static requestSharedDocumentTypes(): Promise<IApiResult<SharedDocumentType[]>> {
        return ApiBase.processGet("/plan-document-types", (response: AxiosResponse) => {
            return response.data.types.map((t: any) => new SharedDocumentType(t.id, t.name));
        });
    }

    public static requestRecentSharedDocuments(): Promise<IApiResult<SharedDocument[]>> {
        return ApiBase.processGet(`/documents/recent`, (response: AxiosResponse) => {
            return response.data.map((value: any) => SharedDocumentApi.readDocument(value));
        });
    }

    public static getDocumentDownload(documentId: number, filename: string, isNewTab: boolean) {
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/${documentId}/${encodeURIComponent(filename)}`;

        if (isNewTab) {
            window.open(url, "_blank");
        } else {
            window.location.assign(url);
        }
    }

    public static getSharedDocumentData(documentId: number): Promise<IApiResult<SharedDocument>> {
        return ApiBase.processGet(`/documents/${documentId}`, (axiosResponse: AxiosResponse) => {
            return SharedDocumentApi.readDocument(axiosResponse.data);
        });
    }

    public static requestSharedDocuments(): Promise<IApiResult<SharedDocument[]>> {
        return ApiBase.processGet(`/documents`, (response: AxiosResponse) => {
            return response.data.map((value: any) => SharedDocumentApi.readDocument(value));
        });
    }
}

