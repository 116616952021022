import * as React from "react";
import {InsightsApi} from "../../api/InsightsApi";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";
import WhitePapers from "../white-paper/WhitePapers.component";
import {ErrorComponent} from "../base/Error.component";
import {IApplicationRootState} from "../../../applicationState";
import {hasAonTrustCompany, insightsEnabled} from "../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";

export interface IWhitePaperPropsFromStore {
    insightsEnabled: boolean;
    aonTrustCompany: boolean;
}

interface IWhitePaperState {
    whitePaperList: WhitePaper[];
}

export class WhitePapersPage extends React.Component<IWhitePaperPropsFromStore, IWhitePaperState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            whitePaperList: [],
        };
    }

    public componentDidMount() {
        InsightsApi.requestWhitePapers().then((response) => {
            this.setState({whitePaperList: response.data!});
        });
    }

    public render() {
        if (!this.props.insightsEnabled || this.props.aonTrustCompany) {
            return <ErrorComponent/>;
        }

        return <div className={"main-content"} data-testid="white-papers-page">
            <WhitePapers whitePaperList={this.state.whitePaperList}/>
        </div>;
    }
}

export const mapStateToProps = (state: IApplicationRootState): IWhitePaperPropsFromStore => {
    return {
        insightsEnabled: insightsEnabled(state),
        aonTrustCompany: hasAonTrustCompany(state),
    };
};

const connectedWhitePapersPage = connect(mapStateToProps)(WhitePapersPage);

export default multipleApiRequesterWrapper(
    connectedWhitePapersPage, []
);


