import * as React from "react";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {
    clientUploadsEnabled,
    currentPlan,
    currentUserEmail,
    emailEnabled, hasAonTrustCompany,
    userType
} from "../../../mainReducerMapSelectors";
import {navigateTo} from "../../../navigateTo";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UploadFile} from "../../model/UploadFile.model";
import {secondaryButtonStyle, uploadFileButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {getElementById} from "../../utils/browserUtil";
import {formatNumericDate} from "../../utils/dateUtil";
import {getErrorSpecificClientMessage, throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {TODO} from "../../utils/todo";
import {ErrorComponent} from "../base/Error.component";
import HeaderActionsTemp from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {LoadingComponent} from "../base/Loading.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {HiddenFileInput} from "../shared-documents/HiddenFileInput.component";
import SharedDocumentsAction from "../shared-documents/SharedDocuments.actions";
import {ISharedDocumentsPropsFromState} from "../shared-documents/SharedDocuments.reducer";
import {ApiError} from "../../model/ApiError.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {EmailNotificationSection} from "./EmailNotificationSection";
import {PlanSelect} from "./PlanSelect";
import {UploadFilesComponent} from "./UploadFiles.component";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {getTranslation} from "../../utils/translationUtil";
import {FormattedMessage} from "react-intl";
import {planName} from "../../utils/planUtil";
import {hasConsultantLevelAccess, isAdminUser, isAtcAdminUser, isThirdPartyUser} from "../../utils/sessionUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IUploadPageActions {
    actions: {
        requestDocumentTypes: typeof SharedDocumentsAction.requestDocumentTypes;
        notifyFileUploadComplete: typeof SharedDocumentsAction.notifyFileUploadComplete;
        notifyEmailFailure: typeof HeaderActionsTemp.setHeaderNotification;
    };
}

export interface IUploadPageProps {
    userType: UserTypeEnum;
    currentPlan: IPlanInfo;
    emailEnabled: boolean;
    aonTrustCompany: boolean;
}

export type UploadPageProps =
    IUploadPageActions & IUploadPageProps & ISharedDocumentsPropsFromState;

interface IUploadResult {
    name: string;
    result?: number;
    errorNumber?: number;
}

export const UPLOAD_EMAIL_SUBJECT = "A new upload is available on the Aon Investment Consulting Client Portal";
export const ATC_UPLOAD_EMAIL_SUBJECT = "A new upload is available on the Aon Collective Investment Trust Portal";

export const UploadPage: React.FunctionComponent<UploadPageProps> = (props) => {
    const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [filesUploading, setFilesUploading] = useState<boolean>(false);
    const [selectedPlans, setSelectedPlans] = useState<number[]>([]);
    const [comment, setComment] = useState<string>("");
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [toggleEnabled, setToggleEnabled] = useState<boolean>(false);
    const [thirdPartyEnabled, setThirdPartyEnabled] = useState<boolean>(false);

    function getFileTitle(fileName: string) {
        const lastIndex = fileName.lastIndexOf(".");
        return (lastIndex < 0) ? fileName : fileName.substring(0, fileName.lastIndexOf("."));
    }

    useEffect(() => {
        if(props.currentPlan) {
            setSelectedPlans([props.currentPlan.id]);
        }

        if(props.userType === UserTypeEnum.CLIENT) {
            setToggleEnabled(true);
        }

        const maybeDocumentTypes = props.documentTypes.data;
        if (!maybeDocumentTypes || maybeDocumentTypes.length <= 0) {
            props.actions.requestDocumentTypes();
        }
    }, []);

    useEffect(() => {
        const count = selectedFiles?.filter(file => file.thirdPartyAccess).length;
        setThirdPartyEnabled(!!count);
    }, [selectedFiles]);

    if (props.userType === UserTypeEnum.CLIENT && !props.clientUploadsEnabled) {
        return <ErrorComponent/>;
    }

    if (!props.documentTypes) {
        return <LoadingComponent/>;
    }

    if (filesUploading) {
        return <LoadingSpinner/>;
    }

    const headerLine = `${getTranslation("upload.upload-files-title", "Upload Files")} — ${planName()}`;

    function hasEditAccess() {
        return props.aonTrustCompany
            ? (isAtcAdminUser() || isAdminUser())
            : hasConsultantLevelAccess()
                ? true
                : !isAtcAdminUser() && !isThirdPartyUser() && props.clientUploadsEnabled;
    }
    function renderPageWithFiles(inHeaderLine: string) {
        return (
            <div className="main-content upload-page" data-testid="upload-page">
                <div className="upload-page-container-header__with-files">
                    <h1 className="upload-page-header">{inHeaderLine}</h1>
                </div>
                {renderPlanSelectComponentAndSpacer()}
                <div className="upload-page-choose-files-title">
                    <FormattedMessage id="upload.choose-files"
                                      defaultMessage="Choose Files" />
                </div>
                {renderDropZone("link-20 upload-page-body-dropzone")}
                <HiddenFileInput action={handleFilesSelection} multiple={true}/>
                <UploadFilesComponent
                    documentTypes={props.documentTypes}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    submitClicked={submitClicked}
                />
                <EmailNotificationSection
                    emailEnabled={props.emailEnabled}
                    selectedPlans={selectedPlans}
                    toggleEnabled={toggleEnabled}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    setToggleEnabled={setToggleEnabled}
                    setComment={setComment}
                    userType={props.userType}
                    thirdPartyEnabled={thirdPartyEnabled}
                />
                <div>
                    <div className="upload-form__submit-and-cancel-buttons">
                        <RaisedButton
                            className="upload-page__cancel-button"
                            style={secondaryButtonStyle}
                            primary={false}
                            onClick={() => navigateTo("/shared-documents")}>
                            <FormattedMessage id="upload.cancel"
                                              defaultMessage="Cancel" />
                        </RaisedButton>
                        {/* eslint-disable @typescript-eslint/no-misused-promises */}
                        <RaisedButton
                            className="upload-page__submit-button"
                            style={uploadFileButtonStyle}
                            onClick={submitForm}
                            primary={true}
                            disabled={selectedUsers.length === 0 && toggleEnabled}>
                            <FormattedMessage id="upload.submit"
                                              defaultMessage="Submit" />
                        </RaisedButton>
                    </div>
                </div>
            </div>);
    }

    function renderPageWithoutFiles(inHeaderLine: string) {
        return (<div className="main-content upload-page" data-testid="upload-page-no-files">
            <div className="upload-page-container-header__no-files">
                <h1 className="upload-page-header">{inHeaderLine}</h1>
            </div>
            {renderPlanSelectComponentAndSpacer()}
            <div className="upload-page-choose-files-title">
                <FormattedMessage id="upload.choose-files"
                                  defaultMessage="Choose Files" />
            </div>
            {renderDropZone("link-20 upload-page-body-dropzone")}
            <HiddenFileInput action={handleFilesSelection} multiple={true}/>
            <div>
                <div className="upload-form__cancel-buttons">
                    <RaisedButton
                        className="upload-page__cancel-button"
                        style={secondaryButtonStyle}
                        primary={false}
                        onClick={() => navigateTo("/shared-documents")}>
                        <FormattedMessage id="upload.cancel"
                                          defaultMessage="Cancel" />
                    </RaisedButton>
                </div>
            </div>
        </div>);
    }

    const showSelectDialog = () => {
        getElementById("the-hidden-file-input").click();
    };

    function renderDropZone(className: string) {
        return <div className={className}
                    onClick={showSelectDialog}
                    onDragEnter={(e) => {
                        e.preventDefault();
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                    }}
                    onDrop={handleFilesSelectionForDrop}
        >
            <div className="upload-page-button far fa-cloud-upload"/>
            <div className="upload-page-text">
                <FormattedMessage id="upload.drag-files-desc"
                                  defaultMessage="Drag files from your computer or click to browse" />

            </div>
        </div>;
    }

    function renderPlanSelectComponentAndSpacer() {
        return <div>
                <PlanSelect
                    onSelectElementsCallBack={onSelectPlanChange}
                    selectedElements={selectedPlans}
                    currentPlan={props.currentPlan}
                    userType={props.userType}
                >
                    <div className="upload-page_plan-select-header">
                        <div className="upload-page_plan-select-header-title">
                            <FormattedMessage id="upload.select-plans"
                                              defaultMessage="Select Plans" />
                        </div>
                        <div className="upload-page_plan-select-header-subtitle">
                            <FormattedMessage id="upload.select-plans-desc"
                                  defaultMessage="All files in the upload will be assigned to the selected plans." />
                        </div>
                    </div>
                </PlanSelect>
                <div className="upload-page-plan-select-bottom-spacer"/>
            </div>;
    }

    const onSelectPlanChange = (inSelectedPlans: number[]) => {
        setSelectedPlans([...inSelectedPlans]);
    };

    const handleFilesSelectionForDrop =
        (e: { dataTransfer: { files: FileList | null, items: DataTransferItemList | null } }) => {
            (e as any).preventDefault();
            if (e.dataTransfer.items &&
                Array.from(e.dataTransfer.items).some((item) => item.webkitGetAsEntry()!.isDirectory)) {
                return;
            }
            handleFiles(e.dataTransfer.files);
        };

    const handleFilesSelection = (e: { target: { files: FileList | null } }) => {
        handleFiles(e.target.files);
    };

    function handleFiles(files: FileList | null) {
        const getMaxKey = (uploadFiles: UploadFile[]): number => {
            return uploadFiles.map((x: UploadFile) => x.key).sort((a, b) => a - b)[uploadFiles.length - 1];
        };
        const maxKey = selectedFiles.length ? getMaxKey(selectedFiles) + 1 : 0;
        const newSelectedFiles: UploadFile[] = files !== null
            ? Array.from(files).map(createSelectedFile(maxKey))
            : [];

        setSelectedFiles([...newSelectedFiles, ...selectedFiles]);
        setSubmitClicked(false);
    }


    const createSelectedFile = (maxKey: number) => {
        return (file: File, idx: number) => (
            new UploadFile(
                file,
                maxKey + idx,
                 getFileTitle(file.name),
                false,
                undefined,
                undefined,
            ));
    };

    const submitForm = () => {
        setSubmitClicked(true);
        if (selectedFiles.some((x) => !x.selectedDocumentType || !x.date)) {
            return;
        }
        if (selectedPlans.length <= 0) {
            return;
        }
        setFilesUploading(true);

        const results = selectedFiles.map((file) => {
            const filename = file.file.name;

            const createSuccess = (value: number): IUploadResult => {
                return ({result: value, name: filename, errorNumber: undefined});
            };

            const createFailure = (errorNumber: number): IUploadResult => {
                return ({result: undefined, name: filename, errorNumber});
            };

            return submitFile(file)
                .then((value: IApiResult<number>) => createSuccess(value.data!))
                .catch((error: ApiError) => createFailure(error.errorNumber));
        });

        return Promise.all(results).then((resultValues) => {
            const failureMessages = resultValues
                .filter((result) => result.errorNumber ?? false)
                .map((result) => {
                    return `${getTranslation(
                        "upload.upload-failed", "Upload Failed:"
                    )} ${result.name}${getErrorSpecificClientMessage(result.errorNumber!)}`;
                })
                .join("\n");

            const success = failureMessages.length === 0;

            const message = success
                ? getTranslation("upload.upload-success","All files successfully uploaded.")
                : failureMessages;

            const successCount = resultValues.filter((value) => value.result).length;

            const successfulDocumentIds = resultValues.filter((it) => it.result).map((it) => it.result) as number[];

            const baseUrl = window.location.origin + "/shared-documents/download/";

            if (shouldSendEmail() && (successCount > 0)) {
                const trimmedComment = comment!.trim()
                    ? comment!.trim()
                    : getTranslation("upload.email-default-message", "You have new shared documents.");

                const subject = getTranslation("upload.email-subject",
                    props.aonTrustCompany ? ATC_UPLOAD_EMAIL_SUBJECT : UPLOAD_EMAIL_SUBJECT);

                const postedByMessage = getTranslation("upload.email-posted-by", "Documents posted by");

                SharedDocumentApi
                    .sendEmail(successfulDocumentIds, selectedUsers!, baseUrl, trimmedComment, subject, postedByMessage)
                    .catch(() => {
                        const errorMessage = {
                            message: "Failed to generate email notification",
                            notificationType: NotificationTypes.FAILURE,
                        };
                        props.actions.notifyEmailFailure(errorMessage, 5000);
                    });
            }

            props.actions.notifyFileUploadComplete(success, message);
            navigateTo("/shared-documents");
        }, () => {
            TODO("FILE UPLOAD FAILURE--BEHAVIOR NOT SPECIFIED!!! (Talk to Jeff!)");
        });
    };

    const submitFile: (uploadFile: UploadFile) => Promise<IApiResult<number>> = (uploadFile: UploadFile) => {
        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(uploadFile.file));
        formData.append("title", uploadFile.title);
        formData.append("date", formatNumericDate(uploadFile.date!));
        formData.append("documentType", `${uploadFile.selectedDocumentType}`);
        formData.append("planIds", selectedPlans.toString());
        formData.append("email", props.currentUserEmail);
        formData.append("thirdPartyAccess", `${uploadFile.thirdPartyAccess || false}`);
        return SharedDocumentApi.uploadSharedDocumentMultiPlans(formData);
    };

    const shouldSendEmail = () => {
        return ((toggleEnabled || props.userType === UserTypeEnum.CLIENT)
            && props.emailEnabled);
    };

    const returnToHome = () => {
        navigateTo("/home");
        return null;
    };

    return hasEditAccess()
        ? selectedFiles && selectedFiles.length > 0
            ? renderPageWithFiles(headerLine)
            : renderPageWithoutFiles(headerLine)
        : returnToHome();

};

export const mapStateToProps = (state: IApplicationRootState):
    IUploadPageProps & ISharedDocumentsPropsFromState => {
    return {
        ...state.sharedDocumentsRootState!,
        clientUploadsEnabled: clientUploadsEnabled(state),
        userType: userType(state),
        currentPlan: currentPlan(state),
        emailEnabled: emailEnabled(state),
        currentUserEmail: currentUserEmail(state),
        aonTrustCompany: hasAonTrustCompany(state)
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): IUploadPageActions => {
    return {
        actions: bindActionCreators({
            requestDocumentTypes: SharedDocumentsAction.requestDocumentTypes,
            notifyFileUploadComplete: SharedDocumentsAction.notifyFileUploadComplete,
            notifyEmailFailure: HeaderActionsTemp.setHeaderNotification,
        }, dispatch),
    };
};

export default connect<ISharedDocumentsPropsFromState, IUploadPageActions, void>
(mapStateToProps, mapDispatchToProps)(UploadPage);


