import * as React from "react";
import {useEffect} from "react";
import {PortfolioApi} from "../../api/PortfolioApi";
import {IRangeValue} from "../../api/InFormReportingApi";
import {formatShortDate} from "../../utils/dateUtil";
import {ErrorComponent} from "../base/Error.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {getNumber} from "../../utils/commonUtil";
import {IPlanMarketValueLineChart, PlanMarketValueLineChart} from "./PlanMarketValueLineChart";


export const FinancialReconciliationTab: React.FunctionComponent = () => {
    const [planMarketValues, setPlanMarketValues] = React.useState<IPlanMarketValueLineChart[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);

    const framePlanMarketValueData = (productData: IRangeValue[]): IPlanMarketValueLineChart[] => {
        return productData
            .map((productValue: IRangeValue) => {
                return {
                    asOfDate: formatShortDate(productValue.date),
                    value: getNumber(productValue.value)
                };
            });

    };

    useEffect(() => {
        PortfolioApi.getCurrentPlanMarketValues()
            .then(response => {
                const formatedResponse = framePlanMarketValueData(response);
                setPlanMarketValues(formatedResponse);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));

    }, []);

    if(loading) {
        return <LoadingSpinner/>;
    }

    if(error) {
        return <ErrorComponent/>;
    }

    return <div className="financial-reconciliation-tab__container"
                data-testid="financial-reconciliation-tab__container">
        {planMarketValues.length > 0 && <PlanMarketValueLineChart planMarketValues={planMarketValues}/>}
    </div>;
};
